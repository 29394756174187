/* src/components/AnimatedGraphic.css */

.animation-container {
    position: relative;
    width: 200px;
    height: 200px;
    margin: auto;
  }
  
  .center-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  
  .circle {
    position: absolute;
    width: 30px;
    height: 30px;
    background-image: linear-gradient(
      109.6deg,
      rgba(228, 107, 232, 1) 11.2%,
      rgba(87, 27, 226, 1) 96.7%
    );
    border-radius: 50%;
    top: 85px;
    left: 85px;
    transform-origin: 15px 15px;
    z-index: 0;
  }
  
  .circle1 {
    animation: orbit 4s linear infinite;
  }
  
  .circle2 {
    animation: orbit 4s linear infinite;
    animation-delay: -1.33s;
  }
  
  .circle3 {
    animation: orbit 4s linear infinite;
    animation-delay: -2.66s;
  }
  
  @keyframes orbit {
    from {
      transform: rotate(0deg) translateX(70px) rotate(0deg);
    }
    to {
      transform: rotate(360deg) translateX(70px) rotate(-360deg);
    }
  }
  

  