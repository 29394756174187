/* src/index.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global Button Styles */
button {
  @apply inline-flex items-center justify-center px-6 py-3 rounded-md text-white font-semibold focus:outline-none transition-transform duration-300 ease-in-out;
  background-image: linear-gradient(109.6deg, rgba(228, 107, 232, 1) 11.2%, rgba(87, 27, 226, 1) 96.7%);
  background-size: 200% 200%;
  background-position: 0% 50%;
}

button:hover {
  transform: scale(1.05);
  background-position: 100% 50%;
}

button:active {
  transform: scale(0.95);
}

button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Button Styles for Anchor Tags */
.btn {
  @apply inline-flex items-center justify-center px-6 py-3 rounded-md text-white font-semibold focus:outline-none transition-transform duration-300 ease-in-out no-underline;
  background-image: linear-gradient(109.6deg, rgba(228, 107, 232, 1) 11.2%, rgba(87, 27, 226, 1) 96.7%);
  background-size: 200% 200%;
  background-position: 0% 50%;
}

.btn:hover {
  transform: scale(1.05);
  background-position: 100% 50%;
}

.btn:active {
  transform: scale(0.95);
}

.btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
